import { Link } from "react-router-dom";
import styled from "styled-components";

function Index(){


  return (
    <Wrapper>
      <Image src="/hero.jpg"/>
      <StyledLink to="/album">
        <h2>
          打开 • 常州相册
        </h2>
      </StyledLink>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 400px;
`;

const StyledLink = styled(Link)`
margin-top: 24px;
:hover{
  color: rgb(100,100,100);
}
`

export default Index;