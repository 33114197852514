import styled from "styled-components";
import IconX from "./x.png"


const OnePhotoCard = ( {path, setSelected} ) => {

  const handleClick = () =>{
    setSelected(null);
  }
  return(
    <Wrapper>
      <Header>
        <CloseButton onClick={handleClick}>
          <Icon src={IconX} alt="close icon" />
        </CloseButton>
      </Header>
      <Photo src={path} oncontextmenu="return false;"/>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  align-items: center;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.div`
  cursor: pointer;
  :hover{
   background-color: rgba(0,0,0,0.2);
  }
`

const Icon = styled.img`
  width: 32px;
  margin: 12px;
`;

const Photo = styled.img`
  margin: auto;
  max-width: 90%;
  max-height: 90%;
`;

export default OnePhotoCard;