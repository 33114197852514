import React from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <StyledButton
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      登出
    </StyledButton>
  );
};

const StyledButton = styled.button`
  padding: 4px 12px;
`;

export default LogoutButton;