import styled from "styled-components";

const PhotoCard = ( {path, setSelected} ) => {

  const handleClick = () =>{
    setSelected(path);
  }
 
  return(
    <Wrapper onClick={handleClick}>
      <Photo src={path}/>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  margin: 12px;
  box-sizing: border-box;
  border-color: #F9F9F9;
  border-style: solid;
  border-width: 3px;
  cursor: pointer;

  :hover{
    border-color: #DDDDDD;
  }
`;

const Photo = styled.img`
  height: 200px;
`;

export default PhotoCard;