import { useState, useEffect } from "react";
import styled from "styled-components";

import PhotoCard from "../Components/PhotoCard";
import OnePhotoCard from "../Components/OnePhotoCard";

import photoData from "./album.json";

function Album(){
  const [allPhotos, setAllPhotos] = useState(null);
  const [renderList, setRenderList] = useState(10);
  const [moreButton, setMoreButton] = useState(true);
  const [selected, setSelected] = useState(null);

  useEffect(()=>{    
    setAllPhotos(photoData);
  },[]);


  const handleClikc = () =>{
    if(renderList<allPhotos.length){
      setRenderList(renderList+10);
    }else{
      setMoreButton(!moreButton);
    }
  };

  return (
    <Wrapper>
      <Title>
        <h2>常州相册</h2>
      </Title>
      <PhotoContainer>
        {!allPhotos?"loading":allPhotos.slice(0,renderList).map((element,index)=>{
          return(
            <PhotoCard key={index} path={`/album/${element.path}`} setSelected={setSelected}/>
          )
        })}
        <MoreButton onClick={handleClikc} enalbe={moreButton}> <h3>更多..</h3>  </MoreButton>
      </PhotoContainer>
      {selected===null?"":<OnePhotoCard path={selected} setSelected={setSelected}/>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
`;

const Title = styled.div`
  text-align: left;
  padding: 24px 64px;
`;

const PhotoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 64px;
`;

const MoreButton = styled.button`
  width: 100px;
  height: 100px;
  margin: auto 0;
  ${props => props.enalbe || "display: none;"}
`;

export default Album;