import styled from "styled-components";

const About = () => {

  return(
    <Wrapper>
      <Box>
      <P>
        我是xu，这是我的个人项目。从读大学开始，我已经离开家乡常州有13年了，虽然逢年过节我还是会回到故乡与亲人团聚，但是毕竟大部分时间还是在外地工作。最近三四年由于一直在海外，回家机会也不总是那么好找。再加上居住了整个童年的大板房也即将拆迁，总觉得记忆中东西像细沙一样流失，因而想寻找一些方法来保存些什么。家庭相册计划便是一个简单的尝试。趁着今年元旦回常，我便带着台湾购置的照片扫描仪回国，顺道去爷爷家扫描了一些照片，作为第一批照片资料，收入库中。
      </P>
      <P>
        互联网的好处是世界各地，只要有网络就能够访问到。无论身在何处，还是都能够联系在一起，这可能就是离散时代为数不多的选择。
      </P>
      <P>
	      如果有兴趣参与项目，请发送邮件到邮箱 lavalselike@gmail.com 与我联系。
      </P>
      </Box>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Box = styled.div`
  width: 50%;
`;

const P = styled.p`
  margin-top: 12px;
`;

export default About;