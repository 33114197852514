import React from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <StyledButton
      onClick={() => loginWithRedirect()}
    >
      登入
    </StyledButton>
  );
};

const StyledButton = styled.button`
  padding: 4px 12px;
  margin: 8px;
`;

export default LoginButton;