import React from "react";
import styled from "styled-components";

const loadingImg =
  "https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg";

const Loading = () => {
  return (
    <Wrapper>
      <Image src={loadingImg} alt="Loading..." />
    </Wrapper>
  )
};

const Wrapper = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  width: 120px;
`;

export default Loading;
