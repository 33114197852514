import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./auth/protected-route";
// import env from "react-dotenv";

import About from "./views/About";
import Index from "./views/Index";
import Album from "./views/Album";
import Header from "./Components/Header";
// import Notification from "./Components/Notification";

const App =()=> {

  return (
    <div>
      <Header/>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route path="/about" component={About} />
        <ProtectedRoute path="/album" component={Album}/>
      </Switch>
    </div>
  );
}

export default App;
