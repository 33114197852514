import styled from "styled-components";
import { Link } from "react-router-dom";
// import env from "react-dotenv";
import AuthenticationButton from "./authentication-button";

const Header = () =>{

  return(
    <Wrapper>
      <LeftWrapper>
        <Link to="/">
          <Logo src="/logo.png" />
        </Link>
        <Link to="/about">关于项目</Link>
      </LeftWrapper>
      <RightWrapper>
        <AuthenticationButton />
      </RightWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  background-color: rgb(230,230,230);
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: auto;
`;

const LeftWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
`;

const RightWrapper = styled.div``;

const Logo = styled.img`
  width: 24px;
`;

export default Header;